#LoaderFullWidth{
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #090933 !important;
    left: 0px;
    display: flex;
    align-items: center;
    color: white;
    z-index: 2000;
}

#LoaderFullWidth p{
    width: 100%;
    margin-left: 10px;
    margin-top: 0px;
}

.loader-full{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 40%;
    left: 49%;
}

@media (max-width:768px){
    .loader-gif{left:43%;}
    .loader-full{left: 45%;}
}