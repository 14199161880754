@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}

@media(max-width:768px){
  .carousel{
    height: auto !important;
  }
}


.notfound__container{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #090933;
  color: #FAFAFA;
}
li{
  list-style: none;
}
@font-face {
    font-family: AgencyFb;
    src: url(/static/media/AgencyFBCondensed.90017537.ttf) format("truetype");
}

agm-map {
    height: 300px;
}

#tituloPropiedadesSeccionn{text-align: center;font-size: 35px;font-family: 'Open Sans',sans-serif;}
#tituloPropiedadesSeccionn span{color: rgba(131, 1, 75, 1);}

iframe{
    border: 0px;
}

@media (max-width: 576px) {
    #tituloPropiedadesSeccionn{font-size: 24px!important}
    .example-card{margin-left:0;}
}
#LoaderFullWidth{
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #090933 !important;
    left: 0px;
    display: flex;
    align-items: center;
    color: white;
    z-index: 2000;
}

#LoaderFullWidth p{
    width: 100%;
    margin-left: 10px;
    margin-top: 0px;
}

.loader-full{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 40%;
    left: 49%;
}

@media (max-width:768px){
    .loader-gif{left:43%;}
    .loader-full{left: 45%;}
}
.footer{background-color: whitesmoke;}
.logo-footer{width: 65%;}
.icon-footer{padding: 10px;font-size: 30px;color: #000;}

.info-footer{font-size: 12px;font-weight: bold;font-family: 'Open Sans',sans-serif;}

.copyright{background-color: black;color: white;height:40px;padding-top: 10px;}

@media (max-width: 576px) {
    .copyright{height: 80px;}
}

@media(max-width:768px){
    .logo-footer{width: 100%;}
}
#txtDimension{
    position: relative;
    font-size: 14px;
}

#txtDimension b{font-size: 12px;}

.btnCard{
    color:rgba(131, 1, 75, 1)!important;
}

.avatar{
    background-color:rgba(131, 1, 75, 1)!important;
}


.card-actions{
    justify-content: space-between;
}

.labelEstado{
    position: absolute;
    right: 15px;
    background-color: rgb(27, 27, 27);
    top: 10px;
    color: white;
    font-size: 11px;
    padding: 5px;
    opacity: 0.9;
}

span.Disponible{
    background-color: green;
    color: white;
}
span.Ocupado{
    background-color: orange;
    color: black;
}
span.Reservado{
    background-color: brown;
    color: white;
}
span.Alquilado{
    background-color: blue;
    color: white;
}
span.Vendido{
    background-color: brown;
    color: white;
}



@media (max-width: 768px) { 
    .w-100Producto{
        max-width: 100% !important;
    }

    .producto{
        margin-bottom: 30px;
    }
    #txtDimension{
        font-size: 13px;
    }
}
#btn-restablecerFiltros{
    background-color: #83014b;
    color:white;
    box-shadow: -1px 2px 2px -1px rgb(0, 0, 0.5);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all .2s ease;
}

#btn-restablecerFiltros:hover{
    box-shadow: 1px 3px 3px 1px rgb(0, 0, 0.5);
}
#tituloPropiedadesSeccion{text-align: center;margin-top: 30px;font-size: 35px;font-family: 'Open Sans',sans-serif; text-transform: uppercase;}
#tituloPropiedadesSeccion span{color: rgba(131, 1, 75, 1)!important;}

@media (max-width: 768px) {
    #tituloPropiedadesSeccion{
        font-size: 24px;
    }
}
.contenedor-banner{
    height:250px;
    background-image: url('https://images.unsplash.com/photo-1593604340846-4fbe9763a8f3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80');
    background-position: center;
    background-size: cover;
    position: relative;
}

.contenedor-banner:before {
	content:'';
	position: absolute;
    top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0,0,0,0.6);
    color: white;
}

.info-contenedor h2{
    font-size: 28px;
    font-weight: 400;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    color: #fff;
}

.paginacion{
    font-size: 18px !important;
}

.info-contenedor{
    display: flex;
    justify-content: space-between;
    z-index: 2000;
    position: absolute;
    top: 23%;
    width: 100%;
}

@media(max-width:768px){
    .info-contenedor{
        top: 16%;
        height: 160px;
    }
    .info-contenedor h2{
        font-size: 15px;
    }
    .paginacion{font-size: 13px !important;}
}
.container-nosotros{
    position: relative;
    top: -40px;
    margin: 0px 10%;
    padding-left: 15px;
}

#tituloPropiedadesSeccionNosotros{font-size: 35px;font-family: 'Open Sans',sans-serif;}
.subrayado-animado{background-color: rgba(131, 1, 75, 1);opacity:0.8; height: 5px;}

@media (max-width: 768px) { 
    .container-nosotros{
        top:-40px;
        padding-left: 0px;
        margin: 0px 5%;
        font-size: 14px;
        font-family: 'Open Sans',sans-serif;
    }

    #tituloPropiedadesSeccionNosotros{font-size: 25px;}

}
.filtros__propiedades{
    position: fixed;
    top: 0;
    left: -100%;
    transition: all .5s ease;
    margin: 0;
    padding: 25px 15px;
    box-shadow: 0 -1px 8px -2px rgba(2,2,2,.2), 0 0px 1px 0 rgba(3,3,3,.14), 0 1px 3px 0 rgba(0,0,0,0.12);
    width: 15%;
    height: 100vh;
    overflow-y: auto;
    z-index: 10000;
    background-color: white;
}

.filtros__propiedades::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
}

.filtros__propiedades::-webkit-scrollbar-thumb {
    background-color: #83014b;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.seccion__filtro{
    padding: 7px;
    margin: 5px 0px;
}

.seccion__filtro h3{
    color: rgb(131, 1, 75);
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
}   

.seccion__filtro ul{
    padding: 5px 0px;
}

.seccion_ubicaciones ul li {
    margin: 7px 0px;
}

.seccion__filtro ul li .sublista{
    padding: 5px 0px 5px 8px !important;
}

.seccion__filtro ul li .sub_sublista{
    padding: 5px 0px 5px 12px !important;
}


.seccion__filtro ul li input{
    margin: 0px 5px;
}

#seccion_ubicaciones{
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

#seccion_ubicaciones::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

#seccion_ubicaciones::-webkit-scrollbar-thumb {
    background-color: #83014b;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

/* .seccion__filtro.filtros__aplicados .items{
    padding: 10px 0px;
}

.seccion__filtro.filtros__aplicados .items .filtroAplicado{
    background-color: #e5e5e5;
    color: #202020;
    font-size: 13px;
    line-height: 18px;
    border-radius: 22px;
    font-weight: 400;
    border: 1px solid #ccc;
    padding: 4px 2px;
} */

#btn-open-filters{
    width: 42px;
    height: 42px;
    border-radius: 21px;
    cursor: pointer;
}

#btn-open-filters.hide{
    position: absolute;
    right: 5px;
    top: 50%;
}

#btn-open-filters.show{
    position: fixed;
    left: 10px;
    top: 50%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: all .5s ease;
}

.filtros__propiedades.show{
    left: 0;
}

@media (max-width:768px) {
    .filtros__propiedades{
        width: 70%;
    }
}

form .botonEnviar{
    background-color: rgba(131, 1, 75, 1);width: 100%;color: #fff;margin-top: 15px;
    margin: 8px;
}

form .botonEnviar:hover{
    color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: rgba(131, 1, 75, 1)!important;
}

form .__withBorderLeft{
    border-left: solid 1px #83014b;
}

@media(max-width:768px){
    .MuiFormControl-fullWidth {
        width: 100%;
        margin: 10px 0px !important;
    }

    form .botonEnviar{
        margin: 10px 2px;
    }
    form .__withBorderLeft{
        border: none;
    }
}
.infoContacto{
    background-color: #83014b;
    height: 100%;
    color: white !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
}

.infoContacto .item-red{
    margin: 30px 0px;
    width: 100%;
}

.infoContacto .item-red .icon-red{
    font-size: 30px;
}

.infoContacto .item-red .redNombre a, .redNombre{
    color: white;
    font-size: 16px;
}

.__withBorder{
    border-bottom: solid 1px white;
}

.infoContacto .item-red .redNombre a:hover{text-decoration: none;}


.icon-red{font-size:25px;}

.redNombre{font-size: 20px;font-family: 'Open Sans',sans-serif;}

@media (max-width: 768px) {
    .redNombre{font-size: 12px;}
    .icon-red{font-size: 15px;display: block;margin-top: 15px;}
}
.notfound__container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #090933;
    color: #FAFAFA;
}

@media(max-width:768px){
    .notfound__container h1{
        font-size: 20px;
    }
    .notfound__container h4{
        font-size: 18px;
    }
}
.muestraImagenes{
    overflow-y: auto;
    height: 450px;
}

.muestraImagenes img{
    height:80px;
    margin: 0px 10px 10px 0px;
    width: 80%;
}

.carousel-propiedad{
    /* height: 452px; */
}


.slider-div{
    height:600px;
}

.carousel-propiedad .carousel-item img {
    max-height: 100% !important;
}

.carousel-propiedad .carousel-item{height: 400px;}

.carousel-propiedad .carousel-inner{
    display: flex;
    justify-content: center;
    background-color: #333;
    border-radius: 5px;
}

.carousel-propiedad .carousel-item.active{  
    float: none;
    width: auto;
    margin-right: 0px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}

.colorTitle{color: rgba(131, 1, 75, 1);margin-bottom: 15px !important}
.descripcionCasa{font-family: 'Open Sans',sans-serif;}

.tituloCaracteristica{font-weight: bold;margin: 0;}
.tituloPropiedadSeccion{font-size: 35px;font-family: 'Open Sans',sans-serif;}
#fotoMini{cursor: pointer;}
.cont-caja{margin-bottom: 40px;}

@media (max-width:768px){
    .muestraImagenes{
        height: 110px;
    }
    .carousel{
        height: 250px;
    }
    .muestraImagenes img{
        width: 33%;
        height: 100%;
        margin: 0px 0.5px;
    }
    .modal-dialog{
        top:5%;
    }
    .tituloPropiedadSeccion{font-size: 25px;margin-top: 20px;}
    .colorTitle{font-size: 20px!important; margin-bottom: 10px !important;}
    .cont-caja{margin-bottom: 20px;}

    .carousel-propiedad .carousel-control-prev, .carousel-propiedad .carousel-control-next{
        color: black!important;
    }

}

.menu-contacto-info{
    background-color:rgb(131, 1, 75);
    color: #fff;
    width: 100%;
    height:50px;
    display: flex !important;
    align-items: center;
    opacity: 0.9;
}
.icon-social-menu{
    font-size: 20px;
    margin: 0px 15px;
    cursor: pointer;
}

.info-menu-contacto{
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
}

.info-menu-contacto.valores-contacto{font-weight: 300;}

.logo img{width: 220px;}
.nav-link{text-decoration: none; color: black;margin-left:10px;font-size: 14px;}
.nav-link:hover{color: rgba(196, 28, 66, 1);}
.color-black{color:black !important}


#botonMenu{display: none;}


.showw{
    display: block!important;
    left: 0px !important;
    position: absolute !important;
    top: 59px !important;
    width: 63% !important;
    height: 100vh !important;
    overflow-y: hidden !important;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 -1px 8px -2px rgba(2,2,2,.2), 0 0px 1px 0 rgba(3,3,3,.14), 0 1px 3px 0 rgba(0,0,0,0.12);
}

nav{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    background-color: white;
    padding: 0px !important;
    width: 100%;
    z-index: 1000;
}

.scroll{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    background-color: white !important;
}

.redes{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width:1220px){
    .menu-contacto-info{display: none !important;}
}

/*Small devices (landscape phones, 576px and up)*/


/* Medium devices (tablets, 768px and up) */
@media (max-width: 769px) {
    header{
        top: 20px;
        height: 60px;
        background-color:white;
    }
    .enlaces{display: none;}
    .logo{width: 60%;}
    .container-menu{margin: 0px 0px;padding: 0px;}
    .enlaces-mobile{width: 100%;z-index:2000;position: absolute;top: -38px;height: 100%;display: block;}
    .menu-mobile{float:right;background-color:white;height: 100vh;}
    .nav-item{
        text-align: center !important;
        margin-top: 30px;
        margin-right:30px;
        list-style: none;
        font-family: 'Open Sans',sans-serif;font-size: 20px;width: 100%;
    }
    .menu-mobile li a:hover{font-weight: bold;}
    #dropdwon-mobile{margin-right: 35px;background-color: black;}
    #btnCloseMenu{color: white;} 
    .logo img{height: 100%;
        width: 100%;}
    #botonMenu{display: block; font-size: 30px;float: right;width: 20%; margin-left: 40px;cursor: pointer;}
    .menu li{font-size: 15px;}
    .container-menu{margin: 0px 5%;}
    .logo{margin-top:auto}
    .color-black{color:black !important}
 }

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {  }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }
