#txtDimension{
    position: relative;
    font-size: 14px;
}

#txtDimension b{font-size: 12px;}

.btnCard{
    color:rgba(131, 1, 75, 1)!important;
}

.avatar{
    background-color:rgba(131, 1, 75, 1)!important;
}


.card-actions{
    justify-content: space-between;
}

.labelEstado{
    position: absolute;
    right: 15px;
    background-color: rgb(27, 27, 27);
    top: 10px;
    color: white;
    font-size: 11px;
    padding: 5px;
    opacity: 0.9;
}

span.Disponible{
    background-color: green;
    color: white;
}
span.Ocupado{
    background-color: orange;
    color: black;
}
span.Reservado{
    background-color: brown;
    color: white;
}
span.Alquilado{
    background-color: blue;
    color: white;
}
span.Vendido{
    background-color: brown;
    color: white;
}



@media (max-width: 768px) { 
    .w-100Producto{
        max-width: 100% !important;
    }

    .producto{
        margin-bottom: 30px;
    }
    #txtDimension{
        font-size: 13px;
    }
}