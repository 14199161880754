.notfound__container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #090933;
    color: #FAFAFA;
}

@media(max-width:768px){
    .notfound__container h1{
        font-size: 20px;
    }
    .notfound__container h4{
        font-size: 18px;
    }
}