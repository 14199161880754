.filtros__propiedades{
    position: fixed;
    top: 0;
    left: -100%;
    transition: all .5s ease;
    margin: 0;
    padding: 25px 15px;
    box-shadow: 0 -1px 8px -2px rgba(2,2,2,.2), 0 0px 1px 0 rgba(3,3,3,.14), 0 1px 3px 0 rgba(0,0,0,0.12);
    width: 15%;
    height: 100vh;
    overflow-y: auto;
    z-index: 10000;
    background-color: white;
}

.filtros__propiedades::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
}

.filtros__propiedades::-webkit-scrollbar-thumb {
    background-color: #83014b;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.seccion__filtro{
    padding: 7px;
    margin: 5px 0px;
}

.seccion__filtro h3{
    color: rgb(131, 1, 75);
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
}   

.seccion__filtro ul{
    padding: 5px 0px;
}

.seccion_ubicaciones ul li {
    margin: 7px 0px;
}

.seccion__filtro ul li .sublista{
    padding: 5px 0px 5px 8px !important;
}

.seccion__filtro ul li .sub_sublista{
    padding: 5px 0px 5px 12px !important;
}


.seccion__filtro ul li input{
    margin: 0px 5px;
}

#seccion_ubicaciones{
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

#seccion_ubicaciones::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

#seccion_ubicaciones::-webkit-scrollbar-thumb {
    background-color: #83014b;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

/* .seccion__filtro.filtros__aplicados .items{
    padding: 10px 0px;
}

.seccion__filtro.filtros__aplicados .items .filtroAplicado{
    background-color: #e5e5e5;
    color: #202020;
    font-size: 13px;
    line-height: 18px;
    border-radius: 22px;
    font-weight: 400;
    border: 1px solid #ccc;
    padding: 4px 2px;
} */

#btn-open-filters{
    width: 42px;
    height: 42px;
    border-radius: 21px;
    cursor: pointer;
}

#btn-open-filters.hide{
    position: absolute;
    right: 5px;
    top: 50%;
}

#btn-open-filters.show{
    position: fixed;
    left: 10px;
    top: 50%;
    transform: rotate(180deg);
    transition: all .5s ease;
}

.filtros__propiedades.show{
    left: 0;
}

@media (max-width:768px) {
    .filtros__propiedades{
        width: 70%;
    }
}