.menu-contacto-info{
    background-color:rgb(131, 1, 75);
    color: #fff;
    width: 100%;
    height:50px;
    display: flex !important;
    align-items: center;
    opacity: 0.9;
}
.icon-social-menu{
    font-size: 20px;
    margin: 0px 15px;
    cursor: pointer;
}

.info-menu-contacto{
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
}

.info-menu-contacto.valores-contacto{font-weight: 300;}

.logo img{width: 220px;}
.nav-link{text-decoration: none; color: black;margin-left:10px;font-size: 14px;}
.nav-link:hover{color: rgba(196, 28, 66, 1);}
.color-black{color:black !important}


#botonMenu{display: none;}


.showw{
    display: block!important;
    left: 0px !important;
    position: absolute !important;
    top: 59px !important;
    width: 63% !important;
    height: 100vh !important;
    overflow-y: hidden !important;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 -1px 8px -2px rgba(2,2,2,.2), 0 0px 1px 0 rgba(3,3,3,.14), 0 1px 3px 0 rgba(0,0,0,0.12);
}

nav{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    background-color: white;
    padding: 0px !important;
    width: 100%;
    z-index: 1000;
}

.scroll{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    background-color: white !important;
}

.redes{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width:1220px){
    .menu-contacto-info{display: none !important;}
}

/*Small devices (landscape phones, 576px and up)*/


/* Medium devices (tablets, 768px and up) */
@media (max-width: 769px) {
    header{
        top: 20px;
        height: 60px;
        background-color:white;
    }
    .enlaces{display: none;}
    .logo{width: 60%;}
    .container-menu{margin: 0px 0px;padding: 0px;}
    .enlaces-mobile{width: 100%;z-index:2000;position: absolute;top: -38px;height: 100%;display: block;}
    .menu-mobile{float:right;background-color:white;height: 100vh;}
    .nav-item{
        text-align: center !important;
        margin-top: 30px;
        margin-right:30px;
        list-style: none;
        font-family: 'Open Sans',sans-serif;font-size: 20px;width: 100%;
    }
    .menu-mobile li a:hover{font-weight: bold;}
    #dropdwon-mobile{margin-right: 35px;background-color: black;}
    #btnCloseMenu{color: white;} 
    .logo img{height: 100%;
        width: 100%;}
    #botonMenu{display: block; font-size: 30px;float: right;width: 20%; margin-left: 40px;cursor: pointer;}
    .menu li{font-size: 15px;}
    .container-menu{margin: 0px 5%;}
    .logo{margin-top:auto}
    .color-black{color:black !important}
 }

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {  }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }