.footer{background-color: whitesmoke;}
.logo-footer{width: 65%;}
.icon-footer{padding: 10px;font-size: 30px;color: #000;}

.info-footer{font-size: 12px;font-weight: bold;font-family: 'Open Sans',sans-serif;}

.copyright{background-color: black;color: white;height:40px;padding-top: 10px;}

@media (max-width: 576px) {
    .copyright{height: 80px;}
}

@media(max-width:768px){
    .logo-footer{width: 100%;}
}