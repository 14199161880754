.muestraImagenes{
    overflow-y: auto;
    height: 450px;
}

.muestraImagenes img{
    height:80px;
    margin: 0px 10px 10px 0px;
    width: 80%;
}

.carousel-propiedad{
    /* height: 452px; */
}


.slider-div{
    height:600px;
}

.carousel-propiedad .carousel-item img {
    max-height: 100% !important;
}

.carousel-propiedad .carousel-item{height: 400px;}

.carousel-propiedad .carousel-inner{
    display: flex;
    justify-content: center;
    background-color: #333;
    border-radius: 5px;
}

.carousel-propiedad .carousel-item.active{  
    float: none;
    width: auto;
    margin-right: 0px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}

.colorTitle{color: rgba(131, 1, 75, 1);margin-bottom: 15px !important}
.descripcionCasa{font-family: 'Open Sans',sans-serif;}

.tituloCaracteristica{font-weight: bold;margin: 0;}
.tituloPropiedadSeccion{font-size: 35px;font-family: 'Open Sans',sans-serif;}
#fotoMini{cursor: pointer;}
.cont-caja{margin-bottom: 40px;}

@media (max-width:768px){
    .muestraImagenes{
        height: 110px;
    }
    .carousel{
        height: 250px;
    }
    .muestraImagenes img{
        width: 33%;
        height: 100%;
        margin: 0px 0.5px;
    }
    .modal-dialog{
        top:5%;
    }
    .tituloPropiedadSeccion{font-size: 25px;margin-top: 20px;}
    .colorTitle{font-size: 20px!important; margin-bottom: 10px !important;}
    .cont-caja{margin-bottom: 20px;}

    .carousel-propiedad .carousel-control-prev, .carousel-propiedad .carousel-control-next{
        color: black!important;
    }

}
